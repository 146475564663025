import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import "../styles/App.css";
import ValidateEQC from "./ValidateEQC";
import ValidateFlaskEQC from "./ValidateFlaskEQC";
import { QC_RequestList } from "./QC_RequestList";
import { QC_Checkpoints } from "./QC_Checkpoints";

const Pages = (accounts) => {
  return (
    <>
      <Switch>
        <Route path="/eqc">
          <ValidateFlaskEQC account={accounts} />
        </Route>
        <Route path="/validate_eqc">
          <ValidateFlaskEQC account={accounts} />
        </Route>
        <Route path="/requests">
          <QC_RequestList />
        </Route>
        <Route path="/checkpoints">
          <QC_Checkpoints />
        </Route>
        <Route path="/">
          <ValidateFlaskEQC account={accounts} />
        </Route>
      </Switch>
    </>
  );
};

const eqc_App = ({ account }) => {
  return <Pages account={account} />;
};

export default eqc_App;
