import { useEffect, useState, useRef } from "react";
import { Dropdown, Button } from "react-bootstrap";
import Card from "react-bootstrap/Card";
import { Typeahead } from "react-bootstrap-typeahead"; // ES2015
import "react-bootstrap-typeahead/css/Typeahead.css";
import { Toast } from "react-bootstrap";
import { uiConfig } from "./config";
import { Col, Container, Row } from "react-bootstrap";
import axios from "axios";
import loader from "./../styles/images/loader_1.gif";

const ValidateEQC = (account) => {
  const user_details = account.account.account[0];
  const [region_list, set_regions] = useState([]);
  const [countries_list, set_countries] = useState([]);
  const [doc_type_list, set_doc_type] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedDocType, setSelectedDocType] = useState("");
  const [task_id, setInput] = useState("");
  const [countryDisable, setCountryDisable] = useState(true);
  const [docTypeDisable, setDocTypeDisable] = useState(true);
  const [uploadedFileName, setUploadedFileName] = useState(null);
  const inputRef = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [showGrid, setShowGrid] = useState(false);
  const [errorToast, setErrorToast] = useState(false);

  const handleUpload = () => {
    inputRef.current?.click();
  };
  useEffect(() => {
    fetch_regions();
  }, []);

  useEffect(() => {
    setSelectedRegion();
  }, "");

  const fetch_regions = async () => {
    
    axios
      .get(uiConfig.apiURL + "/load_regions")
      .then((response) => {
        console.log("response", response);
        set_regions(response.data);
        
        
        setSelectedRegion(["US"]);
        const formData = new FormData();
        formData.append("region_id", "US");
        bindCountries(formData);
        //bindDocTypes(formData);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const onClear = async (e) => {
    alert(" i am cleaning");
  };

  const onRegionChange = async (e) => {
    setSelectedCountry([]);
    setSelectedDocType([]);
    setSelectedRegion(e);
    set_doc_type([]);
    set_countries([]);

    if (e[0] != undefined && e[0] != "") {
      setCountryDisable(false);
      setDocTypeDisable(false);

      const formData = new FormData();
      formData.append("region_id", e[0]);
      bindCountries(formData);
    } else {
      setCountryDisable(true);
      setDocTypeDisable(true);
    }
  };

  const bindCountries = (formData) => {
    setShowGrid(false);
    axios({
      method: "post",
      url: uiConfig.apiURL + "/load_countries",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        set_countries(response.data);
        bindDocTypes(formData);
        setSelectedCountry(["United States of America"]);
        setCountryDisable(false);
      })
      .catch(function (response) {
        setShowGrid(true);
        console.log(response);
      });
  };

  const bindDocTypes = (formData) => {
    axios({
      method: "post",
      url: uiConfig.apiURL + "/get_document_type",
      data: formData,
      headers: { "Content-Type": "multipart/form-data" },
    })
      .then(function (response) {
        set_doc_type(response.data);
        setShowGrid(true);
        setDocTypeDisable(false);
      })
      .catch(function (response) {
        setShowGrid(true);
        console.log(response);
      });
  };

  const onCountryChange = async (e) => {
    setSelectedCountry(e);
  };

  const onDocTypeChange = async (e) => {
    setSelectedDocType(e);
  };

  const clear_form = async () => {
    debugger;
    setSelectedRegion(["US"]);
    setSelectedCountry(["United States of America"]);
    setCountryDisable(false);
    setSelectedDocType([]);
    setInput("");
    setUploadedFileName("");
    //set_doc_type([]);
    //set_countries([]);
    setDocTypeDisable(false);
  };

  const validate_eqc = async () => {
    setShowGrid(false);
    setErrorToast(false);
    const formData = new FormData();
    debugger;
    if (
      inputRef.current.files[0] != undefined &&
      selectedRegion != undefined &&
      selectedCountry != 0 &&
      selectedDocType != undefined &&
      task_id != "" &&
      user_details.name != undefined &&
      user_details.name != ""
    ) {
      formData.append("file", inputRef.current.files[0]);
      formData.append("region_name", selectedRegion[0]);
      formData.append("country_name", selectedCountry[0]);
      formData.append("doc_type", selectedDocType[0]);
      formData.append("task_id", task_id);
      formData.append("username", user_details.name);
      formData.append("user_email", user_details.username);

      const options = {
        method: "POST",
        body: formData,
      };

      const response = await fetch(uiConfig.apiURL + "/validate_eqc", options);
      const responseMessage = await response.json();
      clear_form();
      setShowToast(true);
      setShowGrid(true);
    } else {
      setErrorToast(true);
      setShowGrid(true);
    }
  };

  const handleDisplayFileDetails = () => {
    debugger;
    inputRef.current?.files &&
      setUploadedFileName(inputRef.current.files[0].name);
  };

  return (
    <>
      <Toast
        className="toastpositon Danger-toast"
        onClose={() => setErrorToast(false)}
        show={errorToast}
        delay={5000}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Error</strong>
        </Toast.Header>
        <Toast.Body>Please fill all the required details</Toast.Body>
      </Toast>

      <Toast
        className="toastpositon success-toast"
        onClose={() => setShowToast(false)}
        show={showToast}
        delay={3000}
        autohide
      >
        <Toast.Header>
          <img src="holder.js/20x20?text=%20" className="rounded me-2" alt="" />
          <strong className="me-auto">Success</strong>
        </Toast.Header>
        <Toast.Body>Request has been added successfully</Toast.Body>
      </Toast>

      {/* <div style={{display: showGrid ? 'block' : 'none' }}> */}
      <div>
        <Col xs={12} md={3} lg={3}></Col>
        <Col xs={12} md={6} lg={6}>
          <Card>
            <Card.Header>
              <label className="m-0">English QC Automation</label>
            </Card.Header>
            <Card.Body>
              <label className="mb-2">Region: </label>
              <Typeahead
                clearButton
                id="ddl_regions"
                labelKey="name"
                onChange={onRegionChange}
                options={region_list}
                placeholder="Select Region..."
                selected={selectedRegion}
              />
              <br />
              <label className="mb-2">Country: </label>
              <Typeahead
                clearButton
                disabled={countryDisable}
                id="ddl_countries"
                onChange={onCountryChange}
                options={countries_list}
                placeholder="Select Country..."
                selected={selectedCountry}
              />
              <br />
              <label className="mb-2">Document Type: </label>
              <Typeahead
                clearButton
                id="ddl_doc_types"
                disabled={docTypeDisable}
                onChange={onDocTypeChange}
                options={doc_type_list}
                placeholder="Select Document Type..."
                selected={selectedDocType}
              />
              <br />
              <label className="mb-2">Task ID: </label>
              <br />
              <input
                value={task_id}
                className="form-control"
                onInput={(e) => setInput(e.target.value)}
              />

              <br />
              <label className="mb-2">Choose File:</label>
              <div class="custom-file">
                <input
                  ref={inputRef}
                  onChange={handleDisplayFileDetails}
                  className="d-none"
                  type="file"
                  accept=".docx"
                  className="custom-file-input"
                  id="validatedCustomFile"
                  required=""
                />
                <label
                  className="custom-file-label"
                  for="validatedCustomFile"
                  onClick={handleUpload}
                >
                  {uploadedFileName ? uploadedFileName : "Choose file"}
                </label>
                <div className="invalid-feedback">
                  Example custom file feedback
                </div>
              </div>
              {/* <input
            ref={inputRef}
            onChange={handleDisplayFileDetails}
            className="d-none"
            type="file"
            accept=".docx"
          /> */}
              {/* <div></div>
          <button
            onClick={handleUpload}
            className={`btn btn-outline-${
              uploadedFileName ? "success" : "primary"
            }`}
          >
            {uploadedFileName ? uploadedFileName : "Upload"}
          </button> */}
              <br />
              <br />
              <br />
              <Button
                as="input"
                type="submit"
                value="Submit"
                onClick={validate_eqc}
              />
              {"      "}
              <a
                href="#"
                className="btn btn-outline-secondary"
                value="Clear"
                onClick={clear_form}
              >
                {" "}
                Clear
              </a>
            </Card.Body>
          </Card>
        </Col>
      </div>
      <div
        style={{ display: showGrid ? "none" : "block" }}
        className="loader_demo"
      >
        <img src={loader}></img>{" "}
      </div>
    </>
  );
};

export default ValidateEQC;
